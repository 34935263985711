import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, DialogContent } from '@mui/material';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import VpnLockOutlinedIcon from '@mui/icons-material/VpnLockOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined';
import styles from './AddProgramDialog.module.scss';
import BaseDialog from '../DialogWrappers/BaseDialog';
import InputField from '../atoms/InputField';
import { FitnessGoalOptions, TRAINING_LEVELS } from '../../constants';
import { strToOptionObj, getEmptyProgram } from '../../utils';

import type { Program, WithOptionalId } from '../../types';

export interface AddProgramDialogProps {
  originalProgram?: Program;
  title: string;
  onClose: () => void;
  addProgram: (newProgram: WithOptionalId<Program>) => void;
  isOpen: boolean;
  trainerId: string;
}

const AddProgramDialog: React.FC<AddProgramDialogProps> = ({
  title,
  isOpen,
  onClose,
  trainerId,
  addProgram,
  originalProgram,
}) => {
  const [name, setName] = useState('');
  const [level, setLevel] = useState(0);
  const [fitnessGoal, setFitnessGoal] = useState<string>('');
  const [isPrivate, setIsPrivate] = useState<boolean>(true);
  const [nameError, setNameError] = useState<boolean>(false);
  const [fitnessGoalError, setFitnessGoalError] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      setName('');
      setLevel(0);
      setFitnessGoal('');
      setIsPrivate(true);
      setNameError(false);
      setFitnessGoalError(false);
    }
  }, [isOpen, originalProgram]);

  const handleSubmit = async () => {
    if (!name) return setNameError(true);
    if (!fitnessGoal) return setFitnessGoalError(true);

    const newProgram = getEmptyProgram(
      name,
      level,
      fitnessGoal,
      isPrivate,
      trainerId,
    );
    addProgram(newProgram);
    onClose();
  };

  return (
    <BaseDialog
      onClose={onClose}
      isOpen={isOpen}
      title={title}
      actions={
        <Button className={styles.submit} onClick={handleSubmit}>
          <span className={styles.submitText}>Create Program</span>
        </Button>
      }
    >
      <DialogContent className={styles.programDataDialog}>
        <div className={styles.row}>
          <InputField
            placeholder="Program Name"
            icon={<EditNoteOutlinedIcon color="primary" />}
            title="Program Name"
            inputName="programName"
            value={name}
            onChange={(value) => {
              setName(value);
              setNameError(false);
            }}
            error={nameError}
          />
          <InputField
            title="Program Level"
            inputName="programLevel"
            icon={<SpeedOutlinedIcon color="primary" />}
            value={level}
            options={TRAINING_LEVELS.map((v, i) => ({ label: v, value: i }))}
            onChange={(newLevel) => {
              const numericLevel = Number(newLevel);
              setLevel(numericLevel);
            }}
          />
        </div>
        <div className={styles.row}>
          <InputField
            placeholder="Choose Fitness Goal"
            title="Fitness Goal"
            icon={<FlagCircleOutlinedIcon color="primary" />}
            inputName="fitnessGoal"
            options={FitnessGoalOptions}
            value={fitnessGoal}
            onChange={(value) => {
              setFitnessGoal(value);
              setFitnessGoalError(false);
            }}
            error={fitnessGoalError}
          />
          <InputField
            icon={<VpnLockOutlinedIcon color="primary" />}
            placeholder="Privacy Setting"
            title="Privacy"
            inputName="privacy"
            value={
              _.isBoolean(isPrivate) ? (isPrivate ? 'Private' : 'Public') : ''
            }
            options={['Public', 'Private'].map((val) => strToOptionObj(val))}
            onChange={(value) => {
              setIsPrivate(value === 'Private');
            }}
          />
        </div>
      </DialogContent>
    </BaseDialog>
  );
};

export default AddProgramDialog;
