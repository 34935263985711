import { Program, WithOptionalId } from '../types';

export const getEmptyProgram = (
  name: string,
  level: number,
  fitnessGoal: string,
  isPrivate: boolean,
  trainerId: string,
): WithOptionalId<Program> => ({
  name,
  level,
  isPrivate,
  trainerId,
  fitnessGoal,
  programItems: [[]],
});

export const getDefaultProgram = (
  trainerId: string,
): WithOptionalId<Program> => ({
  name: '',
  isPrivate: true,
  level: 0,
  trainerId,
  fitnessGoal: '',
  programItems: [[]],
});
