import React, { useMemo, useState } from 'react';
import programRowStyles from './ProgramRow.module.scss';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import EmptyState from '../composites/EmptyState';
import DashboardContentWrapper from '../composites/DashboardContentWrapper';
import ProgramRow from './ProgramRow';
import ListContent from '../composites/ListContent';
import AddProgramDialog from './AddProgramDialog';
import EditProgram from './EditProgram';
import { GymType } from '../../constants';
import { getDefaultProgram } from '../../utils';
import {
  createNewProgram,
  removeProgram,
  updateProgramData,
  // createNewTemplate,
} from '../../api';
import { useLoginData, useRefreshState } from '../../providers';
import EditTemplate from '../Workouts/EditTemplate';

import type {
  Program,
  WithOptionalId,
  TrainingRoutineTemplate,
  TrainingRoutine,
  Day,
} from '../../types';

const programListTitles = [
  'Program Name',
  'Created By',
  '1X1 CLIENTS',
  'WEEKS',
  'Options',
];

interface ProgramsProps {
  isEditMode?: boolean;
  program?: WithOptionalId<Program>;
  onClose?: () => void;
  onSubmit?: (program: WithOptionalId<Program>) => void;
  isContentWrapped?: boolean;
}

const Programs: React.FC<ProgramsProps> = ({
  isEditMode,
  program,
  onClose,
  onSubmit,
  isContentWrapped,
}) => {
  const refreshState = useRefreshState();
  const { trainerData, relatedGyms } = useLoginData();
  const gym = relatedGyms[0];
  const isGymOfTypeSchool = gym?.type === GymType.School;
  const trainer = trainerData?.trainer;
  const trainerPrograms = trainerData?.programs;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedProgram, setSelectedProgram] = useState<string>('');
  const [isAddProgramModalOpen, setIsAddProgramModalOpen] = useState(false);
  const [isEditTemplateModalOpen, setIsEditTemplateModalOpen] = useState(false);
  const [editedProgram, setEditedProgram] =
    useState<WithOptionalId<Program> | null>(
      isEditMode && program ? program : null,
    );
  const [editedTemplate, setEditedTemplate] =
    useState<WithOptionalId<TrainingRoutineTemplate> | null>(null);
  const [editedWorkoutDay, setEditedWorkoutDay] = useState<Day | null>(null);
  const [editedWorkoutWeekIndex, setEditedWorkoutWeekIndex] = useState<
    number | null
  >(null);
  const [editedWorkoutDayIndex, setEditedWorkoutDayIndex] = useState<
    number | null
  >(null);

  const filteredPrograms = useMemo(() => {
    if (!trainerPrograms) return [];
    const searchQueryRegex = new RegExp(searchQuery.toLowerCase());
    return trainerPrograms
      .filter(({ name }) => searchQueryRegex.test(name.toLowerCase()))
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }, [searchQuery, trainerPrograms]);

  const renderEmptyState = () => (
    <EmptyState
      icon={<SummarizeOutlinedIcon />}
      cta={{
        icon: <AddBoxOutlinedIcon color="primary" />,
        label: 'Add A Program',
        onClick: () =>
          !isEditMode &&
          (isGymOfTypeSchool
            ? CreateDefaultProgram()
            : setIsAddProgramModalOpen(true)),
      }}
      text="No Programs Have Been Added"
    />
  );

  if (!trainer) {
    return (
      <EmptyState
        icon={<SummarizeOutlinedIcon />}
        text="No Programs for logged in role."
      />
    );
  }

  const handleSubmitEditProgram = async (
    newProgramData: WithOptionalId<Program>,
  ) => {
    if (isEditMode && onSubmit) {
      onSubmit(newProgramData);
      return { success: true };
    } else {
      const { success, errorMessage } = newProgramData.id
        ? await updateProgramData(newProgramData as Program)
        : await createNewProgram(newProgramData, trainer.id);
      if (!success) {
        return { success, errorMessage };
      }
      await refreshState();
      setIsAddProgramModalOpen(false);
      return { success: true };
    }
  };

  const CreateDefaultProgram = () => {
    const newDefaultProgram = getDefaultProgram(trainer.id);
    setEditedProgram(newDefaultProgram);
  };

  const renderAddProgramDialog = () => (
    <AddProgramDialog
      title="Create A Program"
      trainerId={trainer.id}
      addProgram={setEditedProgram}
      onClose={() => setIsAddProgramModalOpen(false)}
      isOpen={isAddProgramModalOpen}
    />
  );

  const deleteProgram = async (program: Program) => {
    await removeProgram(program.id);
    await refreshState();
  };

  const duplicateProgram = async (program: Program) => {
    const newProgramData = {
      ...program,
      name: `${program.name} - Copy`,
      id: undefined,
    };
    await createNewProgram(newProgramData, trainer.id);
    await refreshState();
  };

  const handleSubmitEditedRoutine = async (
    newTemplateData: WithOptionalId<TrainingRoutineTemplate>,
    shouldAddNewWorkout?: boolean,
  ) => {
    const newProgramData = {
      ...editedProgram,
      programItems: editedProgram?.programItems.map((week, weekIndex) =>
        week && week.length
          ? week.map((programItem, dayIndex) =>
              weekIndex === editedWorkoutWeekIndex &&
              dayIndex === editedWorkoutDayIndex
                ? {
                    ...programItem,
                    routine: newTemplateData.routine,
                    day: editedWorkoutDay,
                  }
                : programItem,
            )
          : [],
      ),
    };

    if (
      shouldAddNewWorkout &&
      newProgramData.programItems &&
      editedWorkoutWeekIndex !== null
    ) {
      if (!newProgramData.programItems[editedWorkoutWeekIndex]) {
        newProgramData.programItems[editedWorkoutWeekIndex] = [];
      }
      newProgramData.programItems[editedWorkoutWeekIndex].push({
        routine: newTemplateData.routine,
        day: editedWorkoutDay,
      });
    }

    setEditedProgram(newProgramData as WithOptionalId<Program>);
    setIsEditTemplateModalOpen(false);
    setEditedWorkoutWeekIndex(null);
    setEditedWorkoutDayIndex(null);
    setEditedTemplate(null);
    setEditedWorkoutDay(null);
    return { success: true };
  };

  const renderContent = () => {
    if (editedProgram) return null;

    return (
      <ListContent
        items={filteredPrograms}
        listHeaderClassName={programRowStyles.listGrid}
        EmptyStateComponent={renderEmptyState}
        titles={programListTitles}
        ItemComponent={({ data }) => (
          <ProgramRow
            program={data}
            deleteProgram={deleteProgram}
            duplicateProgram={duplicateProgram}
            openEditProgramModal={() => setEditedProgram(data)}
            selected={selectedProgram === data.id}
            onSelect={() => setSelectedProgram(data.id)}
            key={data.id}
          />
        )}
      />
    );
  };

  const getDrillInContentEditProgramRoutine = () => {
    if (editedTemplate && isEditTemplateModalOpen && editedProgram) {
      const shouldAddNewWorkout =
        editedWorkoutWeekIndex !== null &&
        editedWorkoutDayIndex ===
          (editedProgram?.programItems?.[editedWorkoutWeekIndex]?.length || 0);
      return (
        <EditTemplate
          onSubmit={(
            newTemplateData: WithOptionalId<TrainingRoutineTemplate>,
          ) => handleSubmitEditedRoutine(newTemplateData, shouldAddNewWorkout)}
          template={editedTemplate}
          setTemplate={
            setEditedTemplate as React.Dispatch<
              React.SetStateAction<WithOptionalId<TrainingRoutineTemplate>>
            >
          }
          onClose={() => {
            setEditedTemplate(null);
            setIsEditTemplateModalOpen(false);
          }}
        />
      );
    }
  };

  const handleCloseEditProgram = () => {
    setEditedTemplate(null);
    setIsEditTemplateModalOpen(false);
    if (!isEditMode) {
      setEditedProgram(null);
    }
    if (onClose) {
      onClose();
    }
  };

  const getDrillInContentEditProgram = () => {
    if (!editedProgram) return null;
    return (
      <EditProgram
        isEditMode={isEditMode}
        onSubmit={handleSubmitEditProgram}
        program={editedProgram}
        setProgram={
          setEditedProgram as React.Dispatch<
            React.SetStateAction<WithOptionalId<Program>>
          >
        }
        onClose={() => handleCloseEditProgram()}
        onEditWorkout={(
          routine: TrainingRoutine,
          weekIndex: number,
          dayIndex: number,
          selectedDay: Day | null,
        ) => {
          setEditedWorkoutWeekIndex(weekIndex);
          setEditedWorkoutDayIndex(dayIndex);
          setEditedTemplate({
            id: undefined,
            name: routine.name,
            isPrivate: true,
            trainerIds: [trainer.id],
            routine: routine,
          });
          setEditedWorkoutDay(selectedDay || null);
          setIsEditTemplateModalOpen(true);
        }}
      />
    );
  };

  const getDrillInContent = () => {
    if (editedTemplate && isEditTemplateModalOpen && editedProgram) {
      return getDrillInContentEditProgramRoutine();
    } else if (editedProgram) {
      return getDrillInContentEditProgram();
    } else return null;
  };

  const handleDashboardBack = () => {
    if (editedProgram && editedTemplate) {
      setEditedTemplate(null);
    } else if (editedProgram) {
      handleCloseEditProgram();
    }
  };

  return (
    <DashboardContentWrapper
      drillInContent={getDrillInContent()}
      searchPlaceholder="Search for Programs"
      title={
        editedTemplate
          ? `Edit Workout ${editedTemplate.name}`
          : editedProgram
            ? `Edit Program ${editedProgram.name}`
            : 'Program'
      }
      setSearchQuery={setSearchQuery}
      isContentWrapped={isContentWrapped}
      actions={[
        {
          icon: <AddBoxOutlinedIcon />,
          label: 'Add A Program',
          onClick: () =>
            !isEditMode &&
            (isGymOfTypeSchool
              ? CreateDefaultProgram()
              : setIsAddProgramModalOpen(true)),
        },
      ]}
      onBack={() => handleDashboardBack()}
      isEditMode={false}
    >
      {renderContent()}
      {renderAddProgramDialog()}
    </DashboardContentWrapper>
  );
};

export default Programs;
